import React from "react"
import { Helmet } from "react-helmet"
import { Button, Col, Container, Row } from "react-bootstrap"
import Header from "../components/Header"
import * as css from "./profile.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import TweetMedia from "../components/public-profile/TweetMedia"
import LoadingDefault from "../components/public-profile/LoadingDefault"
import LoadingMobile from "../components/public-profile/LoadingMobile"
import axios from "../api/axios"
import log from "../components/log"
import ReactTimeAgo from "react-time-ago"
import NotFoundIllustration from "../assets/images/not_found.webp"
import { Link } from "gatsby"
import metaPreviewImage from "../assets/images/thumbnails/thumbnail_index.jpg"
import MetaTags from "../components/MetaTags"
import ModalContactOwner from "../components/profile/ModalContactOwner"

import Confetti from 'react-dom-confetti';

import karrotHbd from "../assets/sounds/karrot_hbd.mp3"
import useSound from "use-sound"
import useInterval from "use-interval"
import { usePageVisibility } from 'react-page-visibility';

// markup
const Profile = () => {
    const isDebug = (process.env.APP_DEBUG == 'true')
    const [handle, setHandle] = React.useState('')
    const [isAdditionalInfoExpanded, setIsAdditionalInfoExpanded] = React.useState(false)

    const [isGettingLoading, setIsGettingLoading] = React.useState(true)
    const [isGettingDataReady, setIsGettingDataReady] = React.useState(false)
    const [isGettingDataFailed, setIsGettingDataFailed] = React.useState(false)
    const [gettingData, setGettingData] = React.useState({} as ProfileDetail)

    const [isContactOwnerModalVisible, setIsContactOwnerModalVisible] = React.useState(false)

    const [play, { stop }] = useSound(karrotHbd, {
        onend: () => {
            play()
            console.log('play again')
        }
    })
    const [birthdayInterval, setBirthdayInterval] = React.useState(0)
    const [isBirthdayPlayed, setIsBirthdayPlayed] = React.useState(false)
    const isPageVisible = usePageVisibility()


    React.useEffect(() => {
        if (typeof location !== 'undefined') {
            setHandle(location.search.replace('?handle=', ''))
        }
    })

    React.useEffect(() => {
        loadProfile(handle)
    }, [handle])


    React.useEffect(() => {
        stop()
        setIsBirthdayPlayed(false)
        setBirthdayInterval(0)
    }, [isPageVisible])


    function loadProfile(handle: string) {
        if (handle == '') {
            return
        }

        let endpoint = '/api/profile/detail?handle=' + handle
        log('debug', 'Calling API', {
            endpoint: endpoint
        })

        const get = async () => {
            setIsGettingLoading(true)
            setIsGettingDataReady(false)

            try {
                const res = await axios.get(endpoint)

                setIsGettingDataFailed(false)
                setGettingData(res.data.data)
                setIsGettingLoading(false)
                setIsGettingDataReady(true)
            } catch (err: any) {
                if (err?.response == undefined) {

                    log("error", 'Tidak dapat menghubungi server, coba lagi.', {
                        endpoint: endpoint,
                        error: err,
                    })
                } else {

                    log("error", err?.response?.data?.message, {
                        endpoint: endpoint,
                        error: err,
                    })
                }
                setIsGettingDataFailed(true)
                setIsGettingDataReady(true)
                setIsGettingLoading(false)
            }
        }
        get()
    }

    useInterval(() => {
        setBirthdayInterval(prev => prev + 1)
    }, 1000)

    return (
        <>
            <MetaTags
                metaDescription="Profil Anabul"
                metaTitle="Profil Anabul"
                metaPath="/profile"
                metaPreviewImage={metaPreviewImage}
            />

            <Header />
            <Container className={css.section}>
                {
                    (isGettingLoading == false && isGettingDataReady && gettingData.timeline != undefined) ? (
                        <>

                            {
                                gettingData.is_birthday == true ? (
                                    <>

                                        <div
                                            className={(isBirthdayPlayed == false ? css.birthdayButton : `${css.birthdayButton} ${css.hidden}`)}
                                            onClick={() => {
                                                play();
                                                setIsBirthdayPlayed(true)
                                            }}>Birthday Surprise! 🎉</div>
                                        <div style={{ zIndex: '20', position: 'fixed', left: '-50px', top: '300px' }}>

                                            <Confetti
                                                active={isBirthdayPlayed && birthdayInterval % 2 == 0}
                                                config={{
                                                    angle: 45,
                                                    elementCount: 143,
                                                    colors: ["#ffbe0b", "#ffd60a", "#ff006e", "#8338ec", "#3a86ff"]
                                                }}


                                            />
                                        </div>
                                        <div style={{ zIndex: '20', position: 'fixed', right: '-50px', top: '300px' }}>
                                            <Confetti
                                                active={isBirthdayPlayed && birthdayInterval % 2 == 1}
                                                config={{
                                                    angle: 138,
                                                    elementCount: 143,
                                                    colors: ["#9d4edd", "#ff0000", "#ccff33", "#f15bb5", "#99d98c"]
                                                }}
                                            />

                                        </div>
                                    </>
                                ) : null
                            }
                            <Row>
                                <Col md={3} sm={12} className={css.leftSide}>
                                    <div className={`${css.avatar}`} data-is-even={gettingData.is_even}>
                                        <div className={css.bioMobile}>
                                            <div className={css.name}>
                                                {gettingData.name}
                                                {
                                                    gettingData.is_verified ? (
                                                        <div className={css.whiteVerifiedBadge}></div>
                                                    ) : null
                                                }
                                            </div>
                                            <div className={css.username}>
                                                @{gettingData.handle}
                                            </div>

                                            <div className={css.infoUnderName}>
                                                <div className={css.subInfo}>
                                                    <div className={css.iconMarker} />
                                                    {gettingData.regency_name}
                                                </div>
                                                <div className={css.dot} />
                                                <div className={css.subInfo}>
                                                    <div className={css.iconCake} />
                                                    {gettingData.date_of_birth}
                                                </div>
                                                <div className={css.dot} />
                                                <div className={css.subInfo}>
                                                    <div className={css.iconBriefcase} />
                                                    {gettingData.anabul_job}
                                                </div>
                                            </div>
                                        </div>
                                        <img src={gettingData.pass_photo} alt={gettingData.name != null ? gettingData.name : 'Pass Photo'} />
                                    </div>

                                    {
                                        gettingData.is_missing ? (
                                            <div className={css.missing} onClick={() => setIsContactOwnerModalVisible(true)}>
                                                <FontAwesomeIcon icon={faExclamationTriangle} /> Sedang Hilang, Klik Disini Jika Menemukan
                                            </div>
                                        ) : null
                                    }

                                    <div className={css.additionalInfo} data-expanded={isAdditionalInfoExpanded}>
                                        <div className={css.expand} onClick={() => {
                                            setIsAdditionalInfoExpanded(true)
                                        }}><span>Lihat Detail Informasi</span></div>
                                        <div className={css.sectionTitle}>Fisik</div>
                                        <div className={css.sectionContent}>
                                            <div className={css.detailList}>
                                                <div className={css.iconPaw} />
                                                <div className={css.name}>Spesies</div><div className={css.value}>
                                                    {gettingData.animal_type == 'dog'
                                                        ? 'Anjing' : null
                                                    }
                                                    {gettingData.animal_type == 'cat'
                                                        ? 'Kucing' : null
                                                    }
                                                    {gettingData.animal_type == 'rodents'
                                                        ? 'Hamster' : null
                                                    }
                                                    {gettingData.animal_type == 'fish'
                                                        ? 'Ikan' : null
                                                    }
                                                    {gettingData.animal_type == 'rabbit'
                                                        ? 'Kelinci' : null
                                                    }

                                                </div>
                                            </div>
                                            <div className={css.detailList}>
                                                <div className={css.iconMale} />
                                                <div className={css.name}>Jenis Kelamin</div><div className={css.value}>{gettingData.anabul_sex}</div>
                                            </div>
                                            <div className={css.detailList}>
                                                <div className={css.iconFur} />
                                                <div className={css.name}>Jenis Bulu</div><div className={css.value}>{gettingData.anabul_coat_type}</div>
                                            </div>
                                            <div className={css.detailList}>
                                                <div className={css.iconComb} />
                                                <div className={css.name}>Ketebalan Bulu</div><div className={css.value}>{gettingData.anabul_fur_density}</div>
                                            </div>
                                            <div className={css.detailList}>
                                                <div className={css.iconLeftEye} />
                                                <div className={css.name}>Mata Kiri</div><div className={css.value}>{gettingData.anabul_left_eye}</div>
                                            </div>
                                            <div className={css.detailList}>
                                                <div className={css.iconRightEye} />
                                                <div className={css.name}>Mata Kanan</div><div className={css.value}>{gettingData.anabul_right_eye}</div>
                                            </div>
                                            <div className={css.detailList}>
                                                <div className={css.iconPaw} />
                                                <div className={css.name}>Ciri Khusus</div><div className={css.value}>{gettingData.anabul_birth_mark}</div>
                                            </div>
                                        </div>
                                        <div className={css.sectionTitle}>Kesehatan</div>
                                        <div className={css.sectionContent}>
                                            <div className={css.detailList}>
                                                <div className={css.iconVaccine} />
                                                <div className={css.name}>Vaksin Rabies</div><div className={css.value}>{gettingData.anabul_rabies_vaccine}</div>
                                            </div>
                                            <div className={css.detailList}>
                                                <div className={css.iconVaccine} />
                                                <div className={css.name}>Vaksin FVRCP</div><div className={css.value}>{gettingData.anabul_fvrcp_vaccine}</div>
                                            </div>
                                            <div className={css.detailList}>
                                                <div className={css.iconVaccine} />
                                                <div className={css.name}>Nomor Chip</div><div className={css.value}>{gettingData.anabul_chip_number}</div>
                                            </div>
                                        </div>
                                        <div className={css.sectionTitle}>Informasi Lain</div>
                                        <div className={css.sectionContent}>
                                            <div className={css.detailList}>
                                                <div className={css.iconBlood} />
                                                <div className={css.name}>Golongan Darah</div><div className={css.value}>{gettingData.anabul_blood_type}</div>
                                            </div>
                                            <div className={css.detailList}>
                                                <div className={css.iconFood} />
                                                <div className={css.name}>Makanan Favorit</div><div className={css.value}>{gettingData.anabul_fav_meal}</div>
                                            </div>
                                            <div className={css.detailList}>
                                                <div className={css.iconConsole} />
                                                <div className={css.name}>Hobi</div><div className={css.value}>{gettingData.anabul_hobby}</div>
                                            </div>
                                            <div className={css.detailList}>
                                                <div className={css.iconMatingStatus} />
                                                <div className={css.name}>Status Kawin</div><div className={css.value}>{gettingData.anabul_mating_status}</div>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                                <Col md={9} sm={12} className={css.rightSide}>

                                    <div className={css.bioDesktop}>
                                        <div className={css.floatRight}>
                                            <div className={css.nikBarCode} style={{ background: 'url(http://bwipjs-api.metafloor.com/?bcid=code128&text=' + gettingData.nik + ')' }} />
                                            <div className={css.nik}>
                                                {gettingData.nik}
                                            </div>
                                            <div className={css.nikLabel}>
                                                NIK
                                            </div>
                                        </div>

                                        <div className={css.name}>
                                            {gettingData.name}
                                            {
                                                gettingData.is_verified ? (
                                                    <div className={css.verifiedBadge}></div>
                                                ) : null
                                            }
                                        </div>
                                        <div className={css.username}>
                                            @{gettingData.handle}
                                        </div>

                                        <div className={css.infoUnderName}>
                                            <div className={css.subInfo}>
                                                <div className={css.iconMarker} />
                                                {gettingData.regency_name}
                                            </div>
                                            <div className={css.dot} />
                                            <div className={css.subInfo}>
                                                <div className={css.iconBriefcase} />
                                                {gettingData.anabul_job}
                                            </div>
                                            <div className={css.dot} />
                                            <div className={css.subInfo}>
                                                <div className={css.iconCake} />
                                                {gettingData.date_of_birth}
                                            </div>
                                        </div>

                                    </div>


                                    <div className={css.tabs}>
                                        <ul>
                                            <li className={css.active}>Garis Waktu</li>
                                            {/* <li>Galeri KTP</li> */}
                                        </ul>
                                    </div>

                                    <div className={css.timeline}>
                                        {
                                            gettingData.timeline.map((tweet) => {
                                                if (tweet.type == 'public_tweet') {
                                                    return (<>
                                                        <a href={tweet.url} target={'_blank'}>
                                                            <div className={`${css.container} ${css.right}`}>
                                                                <div className={css.content}>
                                                                    <div className={css.tweetProfile}>
                                                                        <div className={css.tweetAvatar}>
                                                                            <img src={tweet.avatar} alt={'Avatar'} />
                                                                        </div>
                                                                        <div className={css.tweetSenderName}>
                                                                            <div className={css.tweetSenderFullName}>
                                                                                {tweet.name}
                                                                            </div>
                                                                            <div className={css.tweetSenderHandle}>
                                                                                @{tweet.handle} <div className={css.dot} />
                                                                                <ReactTimeAgo date={new Date(tweet.created_at * 1000)} timeStyle="round" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <p dangerouslySetInnerHTML={{ __html: tweet.content }}></p>
                                                                    <TweetMedia list={tweet.media} />
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </>)
                                                }
                                                if (tweet.type == 'owner_tweet') {
                                                    return (<>
                                                        <a href={tweet.url} target={'_blank'}>
                                                            <div className={`${css.container} ${css.right} ${css.owner}`}>
                                                                <div className={css.content}>
                                                                    <div className={css.tweetProfile}>
                                                                        <div className={css.tweetAvatar}>
                                                                            <img src={tweet.avatar} alt={'Avatar'} />
                                                                        </div>
                                                                        <div className={css.tweetSenderName}>
                                                                            <div className={css.tweetSenderFullName}>
                                                                                {tweet.name}
                                                                            </div>
                                                                            <div className={css.tweetSenderHandle}>
                                                                                @{tweet.handle} <div className={css.dot} />
                                                                                <ReactTimeAgo date={new Date(tweet.created_at * 1000)} timeStyle="round" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <p dangerouslySetInnerHTML={{ __html: tweet.content }}></p>
                                                                    <TweetMedia list={tweet.media} />
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </>)
                                                }
                                                if (tweet.type == 'no_timeline_yet') {
                                                    return (<>
                                                        <a>
                                                            <div className={`${css.container} ${css.right} ${css.noTimelineYet}`}>
                                                                <div className={css.content}>
                                                                    <p dangerouslySetInnerHTML={{ __html: tweet.content }}></p>
                                                                    <TweetMedia list={tweet.media} />
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </>)
                                                }
                                                if (tweet.type == 'born_this_day') {
                                                    return (<>
                                                        <a>
                                                            <div className={`${css.container}  ${css.right} ${css.empty}`}>
                                                                <div className={css.emptyContent}>
                                                                    <div className={css.iconCake} /> {tweet.content}
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </>)
                                                }

                                            })
                                        }

                                    </div>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>

                            {
                                !isGettingLoading && isGettingDataReady && isGettingDataFailed ? (
                                    <>
                                        <Row>
                                            <Col>
                                                <div className={css.notFound}>
                                                    <img src={NotFoundIllustration} alt={'Not Found Illustration'} />
                                                    <h1>Profil Anabul Tidak Ditemukan</h1>
                                                    <h2>Periksa nama anabul atau status finalisasi.</h2>

                                                    <Link to={'/'}>
                                                        <Button>Kembali ke Beranda</Button>
                                                    </Link>
                                                </div>


                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <>
                                        <div className={css.showInDesktop}>
                                            <LoadingDefault />
                                        </div>
                                        <div className={css.showInMobile}>
                                            <LoadingMobile />
                                        </div>
                                    </>
                                )
                            }

                        </>
                    )
                }

            </Container >

            {/* Contact Owner Modal */}
            <ModalContactOwner
                isContactOwnerModalVisible={isContactOwnerModalVisible}
                setIsContactOwnerModalVisible={setIsContactOwnerModalVisible}
                gettingData={gettingData}
                SahabatName={'test'}
            />
        </>
    )
}

export default Profile
