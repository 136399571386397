import { faTelegram, faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as React from "react"
import { Button, Modal } from "react-bootstrap"
import * as css from "./ModalContactOwner.module.css"

const ModalContactOwner = (props: any) => {
    function closeBtn() {
        props.setIsContactOwnerModalVisible(false)
    }

    return (
        <>
            <Modal
                show={props.isContactOwnerModalVisible}
                className={css.contactOwnerModal}
                onHide={() => closeBtn()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Saya Menemukan {props.gettingData.name}!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Jika kamu menemukan {props.gettingData.name}, segera hubungi {props.sahabatName} nomor dibawah:<br />

                    <div className={css.phoneNumber}>
                        {props.gettingData.sahabat_phone_number}
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    {
                        props.gettingData.sahabat_number_on_whatsapp == true || props.gettingData.sahabat_number_on_telegram == true ? (
                            <div className={css.flexButton}>

                                {
                                    props.gettingData.sahabat_number_on_whatsapp == true ? (
                                        <Button onClick={() => {
                                            let phoneNumberInternational = props.gettingData.sahabat_phone_number;
                                            phoneNumberInternational = phoneNumberInternational.replace(/^0+/, '62');
                                            if (typeof window !== 'undefined') {
                                                window.open('https://wa.me/' + phoneNumberInternational, '_blank');
                                            }
                                        }}>
                                            <FontAwesomeIcon icon={faWhatsapp} />  Whatsapp
                                        </Button>
                                    ) : (
                                        <Button variant="outline-secondary" disabled={true}>
                                            <FontAwesomeIcon icon={faWhatsapp} />  Whatsapp
                                        </Button>
                                    )
                                }
                                {
                                    props.gettingData.sahabat_number_on_telegram == true ? (
                                        <Button onClick={() => {
                                            let phoneNumberInternational = props.gettingData.sahabat_phone_number;
                                            phoneNumberInternational = phoneNumberInternational.replace(/^0+/, '62');
                                            if (typeof window !== 'undefined') {
                                                window.open('https://t.me/+' + phoneNumberInternational, '_blank');
                                            }
                                        }}>
                                            <FontAwesomeIcon icon={faTelegram} /> Telegram
                                        </Button>
                                    ) : (
                                        <Button variant="outline-secondary" disabled={true}>
                                            <FontAwesomeIcon icon={faTelegram} /> Telegram
                                        </Button>
                                    )
                                }

                            </div>
                        ) : (
                            <>
                                <div className={css.flexButton}>
                                    <Button onClick={() => {
                                        let phoneNumberInternational = props.gettingData.sahabat_phone_number;
                                        phoneNumberInternational = phoneNumberInternational.replace(/^0+/, '+62');
                                        if (typeof window !== 'undefined') {
                                            window.location.replace("tel:" + phoneNumberInternational);
                                        }
                                    }}>
                                        <FontAwesomeIcon icon={faPhone} /> Buat Panggilan
                                    </Button>
                                    <Button onClick={() => {
                                        let phoneNumberInternational = props.gettingData.sahabat_phone_number;
                                        phoneNumberInternational = phoneNumberInternational.replace(/^0+/, '+62');
                                        if (typeof window !== 'undefined') {
                                            window.location.replace("sms:" + phoneNumberInternational);
                                        }
                                    }}>
                                        <FontAwesomeIcon icon={faEnvelope} /> SMS
                                    </Button>
                                </div>
                            </>
                        )
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalContactOwner
