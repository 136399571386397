import * as React from "react"
import ContentLoader from "react-content-loader"

const LoadingDefault = () => {
    return (
        <>
            <div>
                <ContentLoader viewBox="0 0 1000 1000" backgroundColor="#f5f5f5" foregroundColor="#dfdfdf">
                    {/* Avatar */}
                    <rect x="0" y="0" rx="10" ry="10" width="23%" height="287" />

                    {/* Main Info */}
                    <rect x="255" y="8" rx="4" ry="4" width="20%" height="30" />
                    <rect x="255" y="42" rx="4" ry="4" width="50px" height="15" />
                    <rect x="255" y="66" rx="4" ry="4" width="280px" height="15" />

                    {/* Float Right Info */}
                    <rect x="77%" y="0" rx="2" ry="2" width="230px" height="25" />
                    <rect x="75%" y="30" rx="2" ry="2" width="250px" height="25" />
                    <rect x="980px" y="62" rx="2" ry="2" width="20px" height="12" />

                    {/* Tabs */}
                    <rect x="255" y="97" rx="2" ry="2" width="84px" height="26" />
                    <rect x="255" y="122" rx="2" ry="2" width="100%" height="3" />

                    {/* Timeline */}
                    <rect x="290" y="122" rx="2" ry="2" width="5px" height="1000px" />
                    <rect x="328" y="144" rx="4" ry="4" width="400px" height="300px" />
                    <rect x="283" y="167" rx="40" ry="40" width="20px" height="20px" />
                    <rect x="283" y="980" rx="40" ry="40" width="20px" height="20px" />

                    {/* Additional Info */}
                    <rect x="0" y="315" rx="4" ry="4" width="50px" height="10px" />
                    <rect x="0" y="340" rx="4" ry="4" width="15%" height="12px" />
                    <rect x="0" y="357" rx="4" ry="4" width="14%" height="12px" />
                    <rect x="0" y="375" rx="4" ry="4" width="15%" height="12px" />
                    <rect x="0" y="392" rx="4" ry="4" width="11%" height="12px" />
                    <rect x="0" y="410" rx="4" ry="4" width="12%" height="12px" />
                    <rect x="0" y="427" rx="4" ry="4" width="12%" height="12px" />
                </ContentLoader>
            </div >
        </>
    )
}

export default LoadingDefault
