import * as React from "react"
import ContentLoader from "react-content-loader"

const LoadingMobile = () => {
    return (
        <>
            <div>
                <ContentLoader viewBox="0 0 300 500" backgroundColor="#f5f5f5" foregroundColor="#dfdfdf">
                    {/* Avatar */}
                    <rect x="0" y="0" rx="15" ry="15" width="300" height="355" />

                    {/* Additional Info */}
                    <rect x="10" y="375" rx="4" ry="4" width="50" height="10" />
                    <rect x="10" y="400" rx="4" ry="4" width="200" height="15" />
                    <rect x="10" y="420" rx="4" ry="4" width="150" height="15" />
                    <rect x="10" y="440" rx="4" ry="4" width="170" height="15" />
                    <rect x="10" y="460" rx="4" ry="4" width="180" height="15" />
                    <rect x="10" y="500" rx="4" ry="4" width="170" height="15" />
                </ContentLoader>
            </div >
        </>
    )
}

export default LoadingMobile
