
import * as React from "react"
import * as css from "../../pages/profile.module.css"

const TweetMedia = (list: any) => {

    const mediaList = list.list;
    return (
        <>
            {
                mediaList.length == 1 ? (
                    <div className={`${css.tweetMedia} `}>
                        <div data-type={mediaList[0].type}>
                            <img src={mediaList[0].url} />
                        </div>
                    </div>
                ) : null
            }
            {
                mediaList.length == 2 ? (
                    <div className={`${css.tweetMedia}  ${css.twoImages}`}>
                        <div style={{ background: 'url(' + mediaList[0].url + ')' }} data-type={mediaList[0].type} />
                        <div style={{ background: 'url(' + mediaList[1].url + ')' }} data-type={mediaList[1].type} />
                    </div>
                ) : null
            }
            {
                mediaList.length == 3 ? (
                    <div className={`${css.tweetMedia}  ${css.threeImages}`}>
                        <div className={css.firstImage} style={{ background: 'url(' + mediaList[0].url + ')' }} data-type={mediaList[0].type} />
                        <div className={css.otherImages}>
                            <div style={{ background: 'url(' + mediaList[1].url + ')' }} data-type={mediaList[1].type} />
                            <div style={{ background: 'url(' + mediaList[2].url + ')' }} data-type={mediaList[2].type} />
                        </div>
                    </div>
                ) : null
            }
            {
                mediaList.length == 4 ? (
                    <div className={`${css.tweetMedia}  ${css.fourImages}`}>
                        <div style={{ background: 'url(' + mediaList[0].url + ')' }} data-type={mediaList[0].type} />
                        <div style={{ background: 'url(' + mediaList[1].url + ')' }} data-type={mediaList[1].type} />
                        <div style={{ background: 'url(' + mediaList[2].url + ')' }} data-type={mediaList[2].type} />
                        <div style={{ background: 'url(' + mediaList[3].url + ')' }} data-type={mediaList[3].type} />
                    </div>
                ) : null
            }
        </>
    )
}

export default TweetMedia
